import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import {graphql} from 'gatsby';

const Catering = props => {
  const {totalCount, nodes} = props.data.allFile;
  const {siteMetadata:{title}} = props.data.site;

  return (
    <Layout cat="catering">
      <SEO title={title} />
      <h1>Außergewöhnliches Catering</h1>
      <pre>{JSON.stringify(nodes, null, 2)}</pre>

      <ul>
        {
          nodes.map(node => {
          const {name, room} = node.childMarkdownRemark.frontmatter;
          return <li><Link to="/detail">{name}: {room}</Link></li>})
        }
      </ul>
      Insgesamt {totalCount} Zimmer<br />
      <Link to="/">Startseite</Link>
    </Layout>
  );
}


export const getData = graphql`
{
    site {
        siteMetadata{
            title
            description
        }
    }
    allFile(filter: {sourceInstanceName: {eq: "markdown"}, relativeDirectory: {eq:"caterings"}}) {
        totalCount
        nodes {
          childMarkdownRemark {
            frontmatter {
              name
            }
          }
        }
      }
  }
`

export default Catering